import React from "react";
import { Container } from "react-bootstrap";

import NavBar from "./NavBar";
import BottomBar from "./BottomBar";

const HowToUse = () => {
  return (
    <div className="has-bottom-bar">
      <NavBar type="white" />
      <BottomBar />
      <Container className="blue-text how-to-use-container">
        <h1 className="heading-m pt-4 pb-1 mb-0">За возач</h1>
        <p className="heading-subtext">
          Објавете од каде до каде патувате и намалете ги трошоците на 0!
        </p>
        <div className="text-center pb-4">
          <img src="/images/how-to-use-hero.jpg" />
        </div>
        <h3 className="heading-xxs">1. Објавете превоз</h3>
        <p>
          Kликнете на плусот и следете ги чекорите, не заборавајте да ја
          вклучите опцијата <strong>“Добивај предлог локација”</strong>, доколку
          би сакале да добивате предлози со конкретни адреси за преземање и
          пристигање на патниците. (Пример: патникот сака да го преземете од
          неговиот дом)
        </p>
        <h3 className="heading-xxs">2. Патниците резервираат онлајн</h3>
        <p>
          Откако патниците ќе резервираат во делот <strong>“патувања” </strong>
          ќе може да ги погледнете локациите за подигање и оставање на патниците
          како и нивниот телефонски број. Исто така тука можете да ги погледнете
          и прифатите/одбиете предлозите на патниците.
        </p>
        <h3 className="heading-xxs">
          3. Потврдете кога ќе пристигнете на локацијата за преземање на
          патниците.
        </h3>
        <p>
          Кога ќе пристигнете на локацијата за преземање на патниците/патникот
          не заборавајте да притиснете на копчето <strong>"Стигнав"</strong> кај
          соодветниот патник/локација.
        </p>
        <h3 className="heading-xxs">4. Патувате заедно</h3>
        <p>
          Откако најдовте патници, Ви посакуваме среќен пат и милион безгрижни
          патувања.
        </p>

        <h3 className="heading-xxs">5. Исплата</h3>
        <p>
          Плаќањето се одвива на таков начин што ПАТНИЦИТЕ ви плаќаат во кеш за
          време на патувањето.
        </p>
        <h1 className="heading-m pt-4 pb-1 mb-0">За патник</h1>
        <p className="mb-4 heading-subtext">
          Внесете од каде до каде патувате и патувајте најудобно за минимален
          износ!
        </p>
        <h3 className="heading-xxs">1. Пребарај превоз</h3>
        <p>
          Внесете го градот од каде поаѓате и градот на пристигање како и денот
          кога ви е потребен превозот. Кликнете побарај. Сега може да го
          одберете патувањето кое најмногу ви одговара. Не заборавајте да
          изберете <strong>“ПРЕДЛОГ ЛОКАЦИЈА НА ПОДИГАЊЕ”</strong> и
          <strong>“ПРЕДЛОГ ЛОКАЦИЈА НА ОСТАВАЊЕ”</strong> доколку имате
          специфични барања од каде би сакале да ве подигне/остави возачот.
          Доколку испратите предлог најпрвин возачот треба да го прифати вашиот
          предлог за да се потврди вашата резервација.
        </p>
        <h3 className="heading-xxs">2. Плаќање</h3>
        <p>
          Патникот е обврзан да го исплати надоместокот за патувањето кој што е
          прикажан на самата резервација во делот патувања и тоа директно на
          возачот за време на патувањето. Немате никакви дополнителни трошоци
          или провизии за апликацита.
        </p>
        <h3 className="heading-xxs">3. Откако резервиравте</h3>
        <p>
          Откако резервиравте можете да ја погледнете вашата резервација во
          делот <strong>“патувања”</strong> каде ќе може да му пишите порака на
          возачот.
        </p>
        <h3 className="heading-xxs">
          4. Потврдете кога ќе пристигнете на вашата дестинација.
        </h3>
        <p>
          Кога ќе пристигнете на вашата дестинација не заборавајте да притиснете
          на копчето <strong>"Стигнав"</strong> кај соодветното патување. Овој
          чекор е особено важен за да го назначите вашето патување како завршено
          и оставите рецензија за возачот.
        </p>
        <h3 className="heading-xxs">5. Патувате заедно</h3>
        <p>
          Откако возачот ве зеде, ви посакуваме среќен пат и милион безгрижни
          патувања.
        </p>
        <h3 className="heading-xxs">6. Патувањето не се реализира</h3>
        <p>
          Доколку возачот не се појави или патувањето не се реализира, кликнете
          на копчето <strong>"Превозот не се реализира"</strong>. Каде што ги
          пишувате причините поради кои возењето не се реализира и доколку
          причините се оправдани(Пример: возачот не се појавува), профилот на
          тој возач ќе биде избришан и нема да има можност да го креира
          повторно. Имате 24ч да го направите ова.
        </p>
      </Container>
    </div>
  );
};

export default HowToUse;
